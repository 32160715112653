<template>
  <div class="member-view billing-history-view pb-5">
    <div class="view-header mb-4">
      <p class="heading-medium-big text-secondary mb-3">Welcome back, {{ this.$store.getters['getUserFirstName'] }}</p>
      <h2 class="heading-medium-big">Your billing history</h2>
    </div>

    <validation-observer
      v-if="allDataLoaded"
      ref="billing-history-filters-form"
      tag="form"
      class="search-form flex flex-align-items-center">
      <div class="form-group mb-0">
        <validation-provider
          name="start-at"
          v-slot="{ errors }">
          <div
            :class="{
              'is-date-range': true,
              'vs-wrap-text': true,
              'is-invalid': errors.length
            }">
            <v-date-picker
              v-model="filters.from_date"
              mode="date"
              locale="en-gb"
              class="date"
              :max-date='filters.to_date'
              :popover="{ visibility: 'focus' }">
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  :value="inputValue"
                  v-on="inputEvents"
                  placeholder="From"
                  class="form-control f-input" />
              </template>
            </v-date-picker>
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </div>
        </validation-provider>
      </div>
      <div class="form-group ml-1 mb-0">
        <validation-provider
          name="end-at"
          v-slot="{ errors }">
          <div
            :class="{
              'is-date-range': true,
              'vs-wrap-text': true,
              'is-invalid': errors.length
            }">
            <v-date-picker
              v-model="filters.to_date"
              mode="date"
              locale="en-gb"
              class="date"
              :min-date='filters.from_date'
              :popover="{ visibility: 'focus' }" >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  :value="inputValue"
                  v-on="inputEvents"
                  placeholder="To"
                  class="form-control f-input" />
              </template>
            </v-date-picker>
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </div>
        </validation-provider>
      </div>

      <div class="search-form-buttons flex ml-auto">
        <f-button
          theme="primary"
          :big="true"
          class="m2-1 mb-1-ms ml-0-sm text-nowrap"
          @click.prevent="validateFiltersForm">
          Search
        </f-button>

        <f-button
          theme="warning"
          :big="true"
          class="ml-1 text-nowrap"
          :disabled="resetFiltersDisabled"
          @click.prevent="clearFilters">
          Reset filters
        </f-button>
      </div>
    </validation-observer>

    <div
      v-if="allDataLoaded && additionalParameters.invoices_exists"
      class="text-right">
      <f-button
        :disabled="actionInProgress"
        class=" ml-auto no-shrink mb-1 mt-4"
        theme="light"
        @click.prevent="downloadInvoice">
        Download all invoices
      </f-button>
    </div>

    <f-list
      ref="billing-history-list"
      class="billing-history-list"
      :loaded="allDataLoaded"
      :listSettings="listSettings"
      :listItems="billingHistoryFormatted" />

    <pagination
      :class="{'is-hidden': !allDataLoaded}"
      :meta-data="paginationMetaData" />

    <div
      v-if="!allDataLoaded && !loadError"
      class="loading-spinner-wrapper">
      <div class="loading-spinner pb-3 pt-3">
        <span class="loading-spinner-content">
          Loading data&hellip;
        </span>
      </div>
    </div>

    <f-alert
      v-if="loadError"
      visible
      class="my-5"
      theme="warning">
      <span>
        Loading data failed.
        <a
          href="javascript:window.location.reload();"
          class="lnk lnk-alert">
          Reload page
        </a>
        to try again.
      </span>
    </f-alert>
  </div>
</template>


<script>
import Vue from 'vue';
import debounce from 'lodash.debounce';
import HasFilters from '@/mixins/HasFilters.vue';
import HasPagination from '@/mixins/HasPagination.vue';
import Pagination from '@/components/core/Pagination.vue';
import FormUtils from '@/utils/FormUtils.js';
import ListUtils from '@/utils/ListUtils.js';

export default {
  name: 'billing-history',
  mixins: [
    HasFilters,
    HasPagination
  ],
  components: {
    'pagination': Pagination
  },
  computed: {
    allDataLoaded () {
      return this.loaded && this.additionalParametersLoaded;
    },
    billingHistoryFormatted () {
      if (!this.billingHistory.length) {
        return [];
      }

      return this.billingHistory.map(item => {
        if (item.created_at) {
          item.createdAt = this.formatDateString(item.created_at);
        } else {
          item.createdAt = '-';
        }

        item.transactionValue = '£' + item.value;

        let className = 'text-danger';

        if (item.status === 'complete') {
          className = 'text-success';
        } else if (item.status === 'pending') {
          className = 'text-waring';
        }

        item.status = { text: item.status, additionalClass: className };

        if (!item.has_invoice) {
          item.hideActions = true;
        }

        return item;
      });
    },
    resetFiltersDisabled () {
      return this.filters.from_date === '' && this.filters.to_date === '';
    }
  },
  data () {
    return {
      billingHistory: [],
      loaded: false,
      loadError: false,
      removeInProgress: false,
      actionInProgress: false,
      additionalParameters: null,
      additionalParametersLoaded: false,
      filters: {
        from_date: '',
        to_date: ''
      },
      listSettings: [
        {
          title: 'Date',
          variableName: ['createdAt'],
          type: 'String'
        },
        {
          title: 'Transaction',
          variableName: ['transactionValue'],
          type: 'String'
        },
        {
          title: 'Status',
          variableName: ['status'],
          type: 'Object'
        },
        {
          title: 'Membership Type',
          variableName: ['membership_type'],
          type: 'String'
        },
        {
          title: '',
          variableName: ['actonsList'],
          type: 'actions',
          items: [
            {
              disabled: false,
              callback: this.downloadInvoice,
              label: 'Invoice',
              name: 'invoice',
              theme: 'outline'
            }
          ]
        }
      ]
    }
  },
  mounted () {
    this.loadAdditionalData();
    this.loadFilteredData();
  },
  methods: {
    debouncedLoadFilteredData: debounce(function () {
      this.$bus.$emit('view-filters-save');
      this.loadFilteredData();
    }, 250),
    loadFilteredData () {
      let requestParams = {
        order_direction: 'DESC',
        is_pagination: 1,
        page: this.currentPage
      };

      if (this.filters.from_date) {
        requestParams.from_date = this.formatDate(this.filters.from_date);
      }

      if (this.filters.to_date) {
        requestParams.to_date = this.formatDate(this.filters.to_date);
      }

      ListUtils.loadItemsData(this, {
        endpoint: '/api/member/payments-history',
        method: 'get',
        listField: 'billingHistory',
        params: requestParams
      });
    },
    loadAdditionalData() {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/member/check-invoices',
        method: 'get',
        outputKey: 'additionalParameters',
        loadedKey: 'additionalParametersLoaded',
        errorKey: 'loadError',
        noPagination: true
      });
    },
    validateFiltersForm () {
      this.$bus.$emit('pagination-reset');
      FormUtils.validate(this.$refs['billing-history-filters-form'], this.debouncedLoadFilteredData);
    },
    clearFilters () {
      this.loaded = false;
      this.loadError = false;
      this.filters = {
        from_date: '',
        to_date: ''
      };

      this.loadFilteredData();
      this.$bus.$emit('view-filters-reset');
    },
    formatDateString (date) {
      if (!date) {
        return 'No information';
      }

      let dateObject = new Date(date);

      return [
        dateObject.getDate(),
        dateObject.getMonth() + 1,
        dateObject.getFullYear()
      ].map(n => n < 10 ? '0' + n : n).join('/');
    },
    formatDate (date) {
      if (!date) {
        return null;
      }

      let dateObject = new Date(date);

      return [
        dateObject.getFullYear(),
        dateObject.getMonth() + 1,
        dateObject.getDate()
      ].map(n => n < 10 ? '0' + n : n).join('-');
    },
    downloadInvoice (item) {
      this.actionInProgress = true;
      let outputFileName = 'billing-history.zip';
      let endpointUrl = '/api/member/get-all-invoices';

      if (item && item.transaction_id) {
        outputFileName = item.transaction_id + '.pdf';
        endpointUrl = '/api/member/invoice/' + item.id;
      }

      this.$http.get(endpointUrl, {
        responseType: 'blob',
      }).then(response => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', outputFileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          this.actionInProgress = false;
        } else {
          Vue.swal({
            title: 'An error has occurred during file download',
            html: 'Please try again.',
            icon: 'warning',
            confirmButtonText: 'OK',
            buttonsStyling: true
          });
          this.actionInProgress = false;
        }
      }).catch(error => {
        console.log(error);
        Vue.swal({
          title: 'An error has occurred during file download',
          html: 'Please try again.',
          icon: 'warning',
          confirmButtonText: 'OK',
          buttonsStyling: true
        });
        this.actionInProgress = false;
      });
    }
  }
}
</script>
